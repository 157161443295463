import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

interface FormDataType {
  name: string;
  email: string;
  phone: string;
  description: string;
  experience: string;
  licenseType: boolean | null; // Accept both null and boolean
}

const Career = () => {
  const [formData, setFormData] = useState<FormDataType>({
    name: "",
    email: "",
    phone: "",
    description: "",
    experience: "",
    licenseType: null, // Can be null or boolean
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleLicenseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value === "true" ? true : false;
    setFormData((prev) => ({
      ...prev,
      licenseType: value,
    }));
  };

  const handleFormSubmit = async () => {
    const { name, email, phone, description, experience, licenseType } =
      formData;

    // Validate form fields
    if (!name || !email || !phone || !experience || licenseType === null) {
      toast.error("Please fill out all required fields.");
      return;
    }

    try {
      const zohoFormData = new FormData();
      zohoFormData.append("name", name);
      zohoFormData.append("email", email);
      zohoFormData.append("mobile", phone);
      zohoFormData.append("msg", description);
      zohoFormData.append("experience", experience);
      zohoFormData.append("license", String(licenseType)); // Convert to string if needed

      const response = await axios.post(
        `${API_URL}/account/contact/create/`,
        zohoFormData
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("We Will Contact You Soon");

        setFormData({
          name: "",
          email: "",
          phone: "",
          description: "",
          experience: "",
          licenseType: null,
        });
      } else {
        toast.error("Failed to submit form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  return (
    <div>
      <div
        className="breadcumb-wrapper"
        style={{ backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">Careers</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space">
        <div className="container">
          <div className="about-page-wrap">
            <div className="row gy-40 justify-content-between align-items-center">
              <div className="col-lg-6">
                <div className="img-box3">
                  <div className="img1">
                    <img src="assets/img/teambanner.png" alt="About" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="sec-title text-theme mb-2">Life at Pontis</h4>

                <p className="text-theme" style={{ fontSize: "18px" }}>
                  At Pontis Realty Inc., Brokerage our hiring process ensures
                  that every agent embodies our core values and contributes a
                  diverse range of skills. Whether it's buying or selling, be it
                  commercial or residential properties, we are dedicated to
                  working tirelessly to ensure complete client satisfaction,
                  available around the clock.
                </p>
              </div>

              

              <div className="col-lg-12">
                <div className="appointment-wrap2 bg-white px-5 py-4 rounded shadow">
                  <h2 className="form-title text-theme text-center mb-3">
                    Drop in for a Chat
                  </h2>
                  <p className="text-center mb-4">
                    We deeply respect agents' privacy, and there's no pressure
                    to commit to a contract. Come in to see if we would be a
                    good fit for you!
                  </p>
                  <form>
                    <div className="row g-3">
                      <div className="form-group style-border col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="Name"
                          id="Name"
                          value={formData.name}
                          onChange={handleInputChange}
                          placeholder="Name"
                        />
                      </div>
                     
                      <div className="form-group style-border col-md-6">
                        <input
                          type="email"
                          className="form-control "
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group style-border col-md-6">
                        <input
                          type="text"
                          className="form-control "
                          name="phone"
                          id="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          placeholder="Mobile Number"
                        />
                      </div>
                      <div className="form-group style-border col-12">
                        <label htmlFor="experience" className="form-label">
                          How many years of experience do you have in real
                          estate?
                        </label>
                        <input
                          type="text"
                          className="form-control "
                          id="experience"
                          name="experience"
                          value={formData.experience}
                          onChange={handleInputChange}
                          placeholder="Experience in real estate"
                        />
                      </div>
                      <div className="form-group style-border    col-12">
                        <label htmlFor="licenseType" className="form-label">
                          Do you have a real estate license?
                        </label>
                        <select
                          id="licenseType"
                          className="form-control "
                          name="licenseType"
                          onChange={handleLicenseChange}
                          value={
                            formData.licenseType !== null
                              ? String(formData.licenseType)
                              : ""
                          }
                        >
                          <option value="">Select</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      <div className="form-group col-12 text-center">
                        <button
                          className="btn btn-primary px-4 py-2 "
                          onClick={handleFormSubmit}
                          type="button"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-lg-12">
                <div style={{ margin: "40px 0" }}>
                  <h1
                    style={{
                      fontSize: "2.5rem",
                      fontWeight: "bold",

                      textAlign: "center",
                    }}
                  >
                    EXPAND FUTURE OPPORTUNITIES
                  </h1>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#333",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    Work With A Residential & Commercial Brokerage
                  </p>
                </div>
                <p
                  style={{
                    fontSize: "18px",
                    lineHeight: "1.6",
                  }}
                >
                  At Pontis Realty, we offer tailored support and practical
                  training, enabling you to concentrate on growing your business
                  effortlessly. With our experienced staff and management team,
                  we possess the expertise to help you excel in the highly
                  competitive world of both Residential and Commercial real
                  estate.
                </p>
                <p
                  style={{
                    fontSize: "18px",
                    lineHeight: "1.6",
                  }}
                >
                  Don't take our word for it – let our agents share their
                  experiences! By prioritizing quality over quantity, we curate
                  a select group of top-notch agents, ensuring that you are
                  surrounded by the very best in the industry.
                </p>
                <div style={{ textAlign: "center", margin: "40px 0" }}>
                  <h2
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    WHAT WE OFFER
                  </h2>
                </div>
                <div>
                  <ul style={{ listStyleType: "none", padding: "0" }}>
                    <li style={{ marginBottom: "20px", fontSize: "18px" }}>
                      <strong>Training & Support:</strong> In Person & Online
                      Agent Training for Both Residential & Commercial
                      Divisions. We go at your speed and offer one-on-one sit
                      downs.
                    </li>
                    <li style={{ marginBottom: "20px", fontSize: "18px" }}>
                      <strong>On-Site Marketing:</strong> Our highly skilled
                      marketing specialists have the know-how to establish your
                      brand's visual identity across both print and digital
                      platforms.
                    </li>
                    <li style={{ marginBottom: "20px", fontSize: "18px" }}>
                      <strong>Collaborative Culture:</strong> We function as a
                      boutique brokerage that fosters a cooperative team
                      atmosphere. All of our agents are on the same team.
                    </li>
                    <li style={{ marginBottom: "20px", fontSize: "18px" }}>
                      <strong>Decades of Experience:</strong> Our Broker of
                      Record and Management team have more than two decades of
                      invaluable experience in both Residential & Commercial
                      Industries.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="location-map  z-index-common col-12">
                <div
                  className="contact-map"
                  style={{
                    width: "100%",
                    height: "400px",
                    marginBottom: "20px",
                  }}
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.5063640135295!2d55.22425067562108!3d25.1523746335144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6987c2e54cdf%3A0x6bf7f6d77db8eeb1!2sAl%20Shafar%20Investment%20Building!5e0!3m2!1sen!2sin!4v1725447463719!5m2!1sen!2sin"
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                    }}
                  ></iframe>
                </div>
                <div
                  className="location-map-address bg-theme"
                  style={{
                    background: "#f8f9fa",
                    padding: "20px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className="thumb" style={{ marginRight: "15px" }}>
                    <img
                      src="assets/img/property/property_inner_1.jpg"
                      alt="img"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                  <div className="media-body">
                    <h4
                      className="title"
                      style={{
                        margin: "0 0 10px",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Address:
                    </h4>
                    <p
                      className="text"
                      style={{
                        margin: "0",
                        fontSize: "16px",
                        color: "#ffffff",
                      }}
                    >
                      #130, Al Shafar Investment Building, Sheikh Zayed Road,
                      Dubai, UAE
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
