import React from "react";
import Header from "../components/BasicComponents/Header";
import Footer from "../components/BasicComponents/Footer";

const Disclaimer = () => {
  return (
    <div>
      <div
        className="breadcumb-wrapper"
        style={{ backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">Disclaimer</h1>
                {/* <ul className="breadcumb-menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>ABOUT US</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden space">
        <div className="container">
          <div className="about-page-wrap">
            <div className="row gy-10 justify-content-between align-items-center">
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Accuracy of Information
              </h4>

              <p>
                The information provided on this website is for general
                informational purposes only. While Pontis Real Estate L.L.C.
                strives to keep the information up to date and correct, we make
                no representations or warranties of any kind, express or
                implied, about the completeness, accuracy, reliability,
                suitability, or availability of the information, products,
                services, or related graphics contained on the website.
              </p>
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                No Professional Advice
              </h4>

              <p>
                The content on this website does not constitute professional
                advice. You should not rely on any information on this site as a
                substitute for professional advice. Always seek the advice of
                qualified real estate professionals or legal counsel with any
                questions you may have.
              </p>
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Property Listings
              </h4>

              <p>
                All property listings on this website, including new projects
                from builders, are subject to change or removal without notice.
                Prices, availability, and other details may change at any time.
                Interested parties should independently verify all information
                and conduct their own due diligence before making any decisions
                based on the listings.
              </p>
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Third-Party Content
              </h4>

              <p>
                Our website may contain links to third-party websites or
                content. Pontis Real Estate L.L.C. does not control or endorse
                these third-party sites and is not responsible for their content
                or practices.
              </p>

              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Virtual Staging and Images
              </h4>

              <p>
                Some property images may be virtually staged or enhanced for
                illustrative purposes. These images are clearly marked and are
                not a representation of the actual property condition.
              </p>
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Compliance with Local Laws
              </h4>

              <p>
                All transactions facilitated by Pontis Real Estate L.L.C. are
                subject to the laws and regulations of Dubai and the United Arab
                Emirates, including those set forth by the Real Estate
                Regulatory Agency (RERA) and Dubai Land Department (DLD).
              </p>
              <p>
                By using this website, you acknowledge that you have read,
                understood, and agree to these terms, conditions, and
                disclaimers set forth by Pontis Real Estate L.L.C.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
