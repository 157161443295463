import React from "react";
import Header from "../components/BasicComponents/Header";
import Footer from "../components/BasicComponents/Footer";

const Terms = () => {
  return (
    <div>
      <div
        className="breadcumb-wrapper"
        style={{ backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">Terms & Conditions</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden space">
        <div className="container">
          <div className="about-page-wrap">
            <div className="row gy-10 justify-content-between align-items-center">
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Use of Website
              </h4>

              <p>
                By using pontis.ae, you agree to comply with these terms and
                conditions. You may not use our website for any unlawful purpose
                or in any way that could damage or impair the site's
                functionality.
              </p>
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Intellectual Property
              </h4>

              <p>
                All content on pontis.ae, including text, images, logos, and
                trademarks, is the property of Pontis Real Estate L.L.C. and
                protected by copyright laws. You may not reproduce, distribute,
                or use any content without our express written permission.
              </p>
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Property Listings
              </h4>

              <p>
                Property listings on pontis.ae are based on information provided
                by sellers, property developers, and third parties. This
                includes new projects from builders, which are considered under
                the category of sellers. While we strive for accuracy, we do not
                guarantee the completeness or accuracy of any listing
                information. Users should independently verify all information
                before making any decisions based on the listings.
              </p>
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Off-Plan Projects
              </h4>

              <ul>
                <li style={{ listStyle: "disc" }}>
                  Information about off-plan projects on pontis.ae is subject to
                  change and final approval by relevant authorities.
                </li>
                <li style={{ listStyle: "disc" }}>
                  All off-plan purchases are subject to the developer's terms
                  and conditions, which may differ from Pontis Real Estate
                  L.L.C.'s terms.
                </li>
                <li style={{ listStyle: "disc" }}>
                  Completion dates for off-plan projects are estimates and may
                  be subject to delays.
                </li>
              </ul>

              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Resale Properties
              </h4>

              <ul>
                <li style={{ listStyle: "disc" }}>
                  For resale properties listed on pontis.ae, Pontis Real Estate
                  L.L.C. acts as an intermediary and is not responsible for the
                  condition or legal status of the property.
                </li>
                <li style={{ listStyle: "disc" }}>
                  Buyers are responsible for conducting due diligence on resale
                  properties.
                </li>
              </ul>
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Leases
              </h4>
              <ul>
                <li style={{ listStyle: "disc" }}>
                  Pontis Real Estate L.L.C. acts as an intermediary between
                  landlords and tenants in lease agreements for properties
                  listed on pontis.ae.
                </li>
                <li style={{ listStyle: "disc" }}>
                  All lease agreements are subject to Dubai's tenancy laws and
                  regulations.
                </li>
              </ul>
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Limitation of Liability
              </h4>

              <p>
                Pontis Real Estate L.L.C. shall not be liable for any direct,
                indirect, incidental, consequential, or punitive damages arising
                from your use of pontis.ae or our services.
              </p>

              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Governing Law
              </h4>

              <p>
                These terms and conditions are governed by the laws of Dubai,
                United Arab Emirates. Any disputes arising from these terms
                shall be subject to the exclusive jurisdiction of the courts in
                Dubai, United Arab Emirates.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
