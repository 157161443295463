import React from "react";
import Header from "../components/BasicComponents/Header";
import Footer from "../components/BasicComponents/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <div
        className="breadcumb-wrapper"
        style={{ backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">PrivacyPolicy</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-hidden space">
        <div className="container">
          <div className="about-page-wrap">
            <div
              className=" justify-content-between align-items-center privacy-policy"
              style={{
                padding: "20px",
                fontSize: "16px",
                lineHeight: "1.6",
              }}
            >
              <h4
                style={{
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                Information We Collect
              </h4>

              <p>
                Pontis Real Estate L.L.C. collects personal information that you
                voluntarily provide to us, including:
              </p>
              <ul>
                <li style={{ listStyle: "disc" }}>
                  Name, email address, phone number, and mailing address
                </li>
                <li style={{ listStyle: "disc" }}>
                  Property preferences and search criteria
                </li>
                <li style={{ listStyle: "disc" }}>
                  Financial information related to property transactions
                </li>
                <li style={{ listStyle: "disc" }}>
                  Information provided when you contact us or submit inquiries
                </li>
              </ul>
              <h4
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                How We Use Your Information
              </h4>

              <p>We use your personal information to:</p>
              <ul>
                <li style={{ listStyle: "disc" }}>
                  Respond to your inquiries and provide real estate services
                </li>
                <li style={{ listStyle: "disc" }}>
                  Personalize your experience on our website
                </li>
                <li style={{ listStyle: "disc" }}>
                  Process transactions and send related information
                </li>
                <li style={{ listStyle: "disc" }}>
                  Send promotional communications, if you have opted in
                </li>
                <li style={{ listStyle: "disc" }}>
                  Improve our website and services
                </li>
              </ul>

              <h4
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Information Sharing
              </h4>

              <p>We may share your information with:</p>
              <ul>
                <li style={{ listStyle: "disc" }}>
                  Real estate agents, brokers, and property developers to
                  facilitate transactions
                </li>
                <li style={{ listStyle: "disc" }}>
                  Third-party service providers who assist in our operations
                </li>
                <li style={{ listStyle: "disc" }}>
                  Legal and regulatory authorities when required by law
                </li>
              </ul>
              <h4
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Data Security
              </h4>

              <p>
                Pontis Real Estate L.L.C. implements reasonable security
                measures to protect your personal information from unauthorized
                access, disclosure, or destruction.
              </p>

              <h4
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Data Retention
              </h4>

              <p>
                We retain your personal data for as long as necessary to fulfill
                the purposes for which it was collected, including for the
                purposes of satisfying any legal, accounting, or reporting
                requirements. In some circumstances, we may anonymize your
                personal data so that it can no longer be associated with you,
                in which case we may use such information without further notice
                to you.
              </p>
              <h4
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                International Data Transfers
              </h4>

              <p>
                Your personal data may be transferred to, and processed in,
                countries other than the country in which you are resident.
                These countries may have data protection laws that are different
                from the laws of your country. We have taken appropriate
                safeguards to require that your personal data will remain
                protected in accordance with this Privacy Policy.
              </p>

              <h4
                style={{
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Your Rights
              </h4>

              <p>You have the right to:</p>
              <ul>
                <li style={{ listStyle: "disc" }}>
                	Access, correct, or delete your personal information
                </li>
                <li style={{ listStyle: "disc" }}>
                	Opt-out of marketing communications
                </li>
                <li style={{ listStyle: "disc" }}>
                	Request a copy of your data
                </li>
              </ul>
              <p>Contact us at info@pontis.ae for any privacy-related inquiries.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
