import React, { useEffect, useState } from "react";
import stickyLogo from "../../images/ponties.png";
import logo from "../../images/new_dubai.png";
import { useNavigate } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from "axios";
import { toast } from "react-toastify";
import logos from "../../images/ponties.png"
import { Modal } from "react-bootstrap";
const API_URL = process.env.REACT_APP_API_URL;

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buildingType, setBuildingType] = useState<any>([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const getBuildType = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/building/types/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setBuildingType(responseData);
      } else {
        console.error("Request failed with status:", response.status);
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  };

  const offPlan = () => {
    navigate("/properties", { state: { is_property: true } });
    handleClose();
  }
  const allProperties = () => {
    navigate("/properties", { state: {} });
    handleClose();
  }
  const contactForm = () => {
    navigate("/"); // Navigate to the home page where #contact-form exists
    setTimeout(() => {
      const element = document.getElementById("contact-form");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  useEffect(() => {

    getBuildType();

  }, []);
  const commericalFunction = async (id) => {
    try {
      const allcommerical = await getCommericalProperties(id); // Wait for the properties to be fetched

      navigate("/properties", { state: { allcommerical, building_type: buildingType[0]?.id } });
      handleClose();
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };
  const residentialFunction = async (id) => {
    try {
      const allcommerical = await getCommericalProperties(id); // Wait for the properties to be fetched

      navigate("/properties", { state: { allcommerical, building_type: buildingType[1]?.id } });
      handleClose();
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };
  const offPlanFunction = async () => {
    try {

      navigate("/properties", { state: { is_property: true } });
      handleClose();
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };
  const getCommericalProperties = async (id) => {

    try {
      let apiUrl = `${API_URL}/properties/advance/filter/?building_type=${id}`;
      const response = await axios.get(apiUrl);
      if (response.status === 200) {
        const responseData = response.data;
        // setAllProperties(responseData);
        ;
        return responseData
      } else {
        // Handle unexpected status codes, if needed
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching properties:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenModal');
    if (!hasSeenModal) {
      const timer = setTimeout(() => {
        setShowModal(true);  // Open modal after 20 seconds
        localStorage.setItem('hasSeenModal', 'true');  // Mark the modal as shown in localStorage
      }, 20000); // 20000ms = 20 seconds
      return () => clearTimeout(timer);
    }
  }, []);
  const handleFormSubmit = async () => {
    const { name, email, phone, description } = formData;

    if (!name || !email) {
      toast.error("Name and Email are required.");
      return;
    }

    try {
      // Create a form data object
      const zohoFormData = new FormData();
      zohoFormData.append("name", name);
      zohoFormData.append("email", email);
      zohoFormData.append("mobile", phone);
      zohoFormData.append("msg", description);

      // Send form data to Zoho CRM with axios
      const response = await axios.post(
        `${API_URL}/account/contact/create/`,
        zohoFormData
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("We Will Contact You Soon");

        setFormData({
          name: "",
          email: "",
          phone: "",
          description: "",
        });
      } else {
        toast.error("Failed to submit form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };
  return (
    <>

      <Offcanvas show={show} onHide={handleClose}>

        <Offcanvas.Body>


          <div className="mobile-logo">
            <a href="/">
              <img
                src={stickyLogo}
                alt="Ponties"
              // style={{ width: "150px" }}
              />
            </a>
          </div>
          <div className="th-mobile-menu">
            <ul>
              <li className="menu-item-has-children">
                <a style={{ cursor: "pointer" }} onClick={() => allProperties()}>PROPERTIES</a>
              </li>
              
              <li>
                <a style={{ cursor: "pointer" }} onClick={() => offPlan()}>OFF PLAN</a>
              </li>
              <li>
                <a href="/team">PONTISIANS</a>
              </li>
              <li className="menu-item-has-children">
                <a href="/aboutus">ABOUT US</a>
              </li>

              <li>
                <a href="/contactus">CONTACT US</a>
              </li>

              <li>
                <a style={{ cursor: "pointer" }} onClick={() => residentialFunction(buildingType[1]?.id)} >
                  RESIDENTIAL
                </a>
              </li>
              <li>
                <a style={{ cursor: "pointer" }}
                  onClick={() => commericalFunction(buildingType[0]?.id)}
                >
                  COMMERCIAL
                </a>

              </li>

              <li>
                <a
                  id="request_button"
                  href="https://main.dn4kknqkhploe.amplifyapp.com/"
                  className=" style2 "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SIGN IN
                </a>
              </li>
            </ul>
          </div>








        </Offcanvas.Body>
      </Offcanvas>

      <header className="th-header header-layout2">
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div
              className="container-fluid"
              style={{ paddingLeft: "60px", paddingRight: "60px", background: "#fff" }}
            >
              <div className="row align-items-center justify-content-between ">
                <div className="col-auto">
                  <div className="">
                    <a href="/">
                      <img
                        className="logo-img"
                        src={stickyLogo}
                        alt="Ponties"
                      // style={{ width: "200px" }}

                      />
                    </a>
                  </div>
                </div>
                <div className="col-auto fs-18">
                  <nav className="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li className="menu-item-has-children">
                        <a style={{ cursor: "pointer" }} onClick={() => allProperties()}>PROPERTIES</a>
                      </li>
                     
                      <li>
                        <a style={{ cursor: "pointer" }} onClick={() => offPlan()}>OFF PLAN</a>
                      </li>
                      <li>
                        <a href="/team">PONTISIANS</a>
                      </li>
                      <li className="menu-item-has-children">
                        <a href="/aboutus">ABOUT US</a>


                      </li>

                      <li>
                        <a href="/contactus">CONTACT US</a>
                      </li>

                    </ul>
                  </nav>

                </div>
                <div className="col-auto d-xxl-block">
                  {/* <div className="header-button d-none d-lg-block">
                    <a
                      id="request_button"
                      href="https://dashboard.pontis.ae/"
                      className="th-btn style2 "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Login to Pontis
                    </a>
                  </div> */}
                  {/* <div className="header-button d-flex d-lg-none">
                    <button type="button" className="th-menu-toggle sidebar-btn" onClick={handleShow}>
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <div
          className="modal-content"
          style={{
            background: "#f4f4f4",
            borderRadius: "15px",
            padding: "20px",
            maxWidth: "400px",
            margin: "auto",
            border: "none",
          }}
        >
          <i
            className="bi bi-x-lg rgfront"
            style={{}}
            onClick={handleCloseModal}
          ></i>
          <div className="text-center mb-2">
            <img src={logos} alt="logo" style={{ width: "200px" }} />
          </div>
          <h3
            style={{
              textAlign: "center",
              color: "#1C2D37",
              marginBottom: "20px",
              fontSize: "14px",
              textTransform: "uppercase",
            }}
          >
            I am interested
          </h3>
          <form className="widget-property-contact-form" id="customForm">
            <div className="form-group">
              <input
                style={{
                  border: "1px solid #ccc",
                  color: "#333",
                  background: "#fff",
                  borderRadius: "8px",
                  padding: "12px",
                  width: "100%",
                  marginBottom: "15px",
                }}
                type="text"
                className="form-control placeholder-style"
                placeholder="Name"
                id="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <input
                style={{
                  border: "1px solid #ccc",
                  color: "#333",
                  background: "#fff",
                  borderRadius: "8px",
                  padding: "12px",
                  width: "100%",
                  marginBottom: "15px",
                }}
                type="email"
                className="form-control placeholder-style"
                placeholder="Email Address"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <input
                style={{
                  border: "1px solid #ccc",
                  color: "#333",
                  background: "#fff",
                  borderRadius: "8px",
                  padding: "12px",
                  width: "100%",
                  marginBottom: "15px",
                }}
                type="tel"
                className="form-control placeholder-style"
                placeholder="Phone Number"
                id="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <textarea
                name="description"
                id="description"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "12px",
                  width: "100%",
                  height: "120px",
                  marginBottom: "15px",
                }}
                placeholder="Type Your Message"
                className="form-control placeholder-style"
                value={formData.description}
                onChange={handleInputChange}
              ></textarea>
            </div>
            <button
              type="button"
              className="th-btn"
              style={{
                backgroundColor: "#4b0082",
                color: "#fff",
                padding: "12px 20px",
                width: "100%",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                fontSize: "16px",
              }}
              onClick={handleFormSubmit}
            >
              SEND
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Header;
