import React, { useEffect, useState } from "react";
import logo from "../../images/pontilogo.png";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
const Footer: React.FC = () => {
  const [allCities, setAllCities] = useState<any>([]);
  console.log(allCities, "allCities");

  const navigate = useNavigate();
  const propertyState = (cityName) => {
    navigate("/properties", {
      state: {
        city: cityName,
        building_type: "569c3700-61ab-42ad-b792-8019d1a3a994",
      },
    });
  };
  const propertyResState = (cityName) => {
    navigate("/properties", {
      state: {
        city: cityName,
        building_type: "00eb5d1b-1d34-4ebb-84d8-aa6d0a843419",
      },
    });
  };
  const offPlanState = (cityName) => {
    navigate("/properties", {
      state: { city: cityName, is_property: true, building_type: " " },
    });
  };
  const getAllCities = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/state/image/`);
      if ((response.status = 200)) {
        setAllCities(response.data);
      } else {
      }
    } catch (err) {
      toast.error(" Please Contact Administrator");
    }
  };

  useEffect(() => {
    getAllCities();
  }, []);
  return (
    <footer className="footer-wrapper footer-layout2 bg-dark">
      <div className="container">
        <div className="footer-wrap">
          <div className="widget-area">
            <div className="row justify-content-between">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="widget widget_nav_menu footer-widget">
                  <h3 className="widget_title text-white">
                    New Commerical Properties
                  </h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      {allCities?.map((item) => (
                        <li key={item?.id}>
                          <a onClick={() => propertyState(item?.state)} style={{cursor:"pointer"}}>
                            Properties In {item?.state}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-auto">
                <div className="widget widget_nav_menu footer-widget">
                  <h3 className="widget_title text-white">
                    New Residential Properties
                  </h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      {allCities?.map((item) => (
                        <li key={item?.id}>
                          <a onClick={() => propertyResState(item?.state)} style={{cursor:"pointer"}}>
                            Properties In {item?.state}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-auto">
                <div className="widget widget_nav_menu footer-widget">
                  <h3 className="widget_title text-white">Off-Plan Projects</h3>
                  <div className="menu-all-pages-container">
                    <ul className="menu">
                      {allCities?.map((item) => (
                        <li key={item?.id}>
                          <a onClick={() => offPlanState(item?.state)} style={{cursor:"pointer"}}>
                            Off-Plan In {item?.state}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-wrap ">
          <div className="th-social style3 text-center ">
            <a
              href="https://www.facebook.com/profile.php? id=61558502811959
"
            >
              <i className="text-white fab fa-facebook-f"></i>
            </a>
            <a href="https://www.tiktok.com/@pontis.real.estate/">
              <i className="text-white fab fa-tiktok"></i>
            </a>
            {/* <a href="https://www.linkedin.com/"><i className=" text-white fab fa-linkedin-in"></i></a>
                        <a href="https://www.youtube.com/"><i className="text-white fab fa-youtube"></i></a> */}
            <a href="https://www.instagram.com/pontisrealestatedubai/">
              <i className="text-white fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="copyright-wrap bg-title-white">
        <div className="container">
          <div className="row gy-2 align-items-center">
            <div className="col-lg-6">
              <p className="copyright-text">
                Copyright <i className="fal fa-copyright"></i> 2024{" "}
                <a href="/">Pontis</a>, All rights reserved.
              </p>
            </div>
            <div className="col-lg-6 text-center text-lg-end">
              <div className="footer-links">
                <ul>
                  <li>
                    <a href="/terms">Terms of service</a>
                  </li>
                  <li>
                    <a href="/privacypolicy">Privacy policy</a>
                  </li>
                  <li>
                    <Link to="/disclaimer">Disclaimer</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
