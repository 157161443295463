import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SearchLocation from "../components/BasicComponents/searchLocation";
import AutoComplete from "react-google-autocomplete";
import CurrencyFormat from "react-currency-format";
import debounce from "lodash/debounce";
const API_URL = process.env.REACT_APP_API_URL;

interface Location {
  geometry?: {
    location?: {
      lat: () => number;
      lng: () => number;
    };
  };
}
const Properties = () => {
  const navigate = useNavigate();
  const searchData = useLocation();
  const homeCategoryId = searchData?.state?.categoryId;
  const homeLat = searchData?.state?.lat;
  const homeLng = searchData?.state?.lng;
  const homeType = searchData?.state?.type;
  const categoryId = searchData?.state?.building_type;
  const offplan = searchData?.state?.is_property;
  const selectedCity = searchData?.state?.city;
  const seacrHomeText = searchData?.state?.searchText;

  console.log(seacrHomeText, "seacrHomeText");
  console.log(searchData, "this is my searchdata coming");
  console.log(categoryId, "categoryId");



  const [searchText, setSearchText] = useState<any>([]);
  const [finalSearchText, setFinalSearchText] = useState("");
  const [filteredValue, setFilteredValue] = useState<any>([]);
  const [searchProperty, setSearchProperty] = useState<any>([]);
  console.log(searchProperty, "searchProperty");

  const [propertyType, setPropertyType] = useState<any>([]);
  const [allProperties, setAllProperties] = useState<any>([]);
  console.log(allProperties, "allProperties");
  const [pageNumber, setPageNumber] = useState(1);
  const [currentRange, setCurrentRange] = useState([1, 5]);
  const [totalPages, setTotalPages] = useState(1);
  const [categoryPropId, setCategoryPropId] = useState<string>("");
  const [location, setLocation] = useState<Location | null>(null);
  const [place, setPlace] = useState<any>();
  const lati = place?.geometry?.location?.lat();
  const lngi = place?.geometry?.location?.lng();
  console.log(lati, lngi, "SHOW");
  const [buildingType, setBuildingType] = useState<any>([]);
  const [type, setType] = useState<string>("");

  useEffect(() => {
    if (seacrHomeText) {
      setSearchText(seacrHomeText);
      setFinalSearchText(seacrHomeText);
    }
  }, [seacrHomeText]);



  const handleNextPage = () => {
    if (pageNumber < totalPages) {
      setPageNumber((prevPage) => prevPage + 1);
      if (pageNumber === currentRange[1] && pageNumber < totalPages) {
        setCurrentRange([currentRange[0] + 5, currentRange[1] + 5]);
      }
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
      if (pageNumber === currentRange[0] && pageNumber > 1) {
        setCurrentRange([currentRange[0] - 5, currentRange[1] - 5]);
      }
    }
  };

  const handlePageClick = (page: number) => {
    setPageNumber(page);
    if (page < currentRange[0] || page > currentRange[1]) {
      const newRangeStart = Math.floor((page - 1) / 5) * 5 + 1;
      setCurrentRange([newRangeStart, newRangeStart + 4]);
    }
  };

  useEffect(() => {
    const handleLoad = () => {
      getAllProperties();
    };

    window.addEventListener("load", handleLoad);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  /* *******************get property data************/

  const getAllProperties = async () => {
    try {
      let locationParams = `/properties/advance/filter/pagination/?property_label=9c9cd2a0-ef4f-4151-b7ab-17496bd3d6c0&page=${pageNumber}&distance=${5000}`;

      if (homeLat !== undefined && homeLng !== undefined) {
        locationParams += `&latitude=${homeLat}&longitude=${homeLng}`;
      } else if (lati !== undefined && lngi !== undefined) {
        locationParams += `&latitude=${lati}&longitude=${lngi}`;
      }

      if (homeCategoryId !== undefined) {
        locationParams += `&building_type=${homeCategoryId}`;
      } else if (categoryId !== undefined) {
        locationParams += `&building_type=${categoryId}`;
      } else if (categoryPropId !== undefined) {
        locationParams += `&building_type=${categoryPropId}`;
      }

      if (homeType !== undefined) {
        locationParams += `&property_type=${homeType}`;
      } else if (type !== undefined) {
        locationParams += `&property_type=${type}`;
      }

      if (offplan !== undefined) {
        locationParams += `&is_property=${offplan}`;
      }
      if (finalSearchText !== undefined) {
        locationParams += `&property_name=${finalSearchText.toLowerCase()}`;
      }

      if (selectedCity !== undefined) {
        locationParams += `&state=${selectedCity}`;
      }
      const response = await axios.get(`${API_URL}${locationParams}`);

      if (response.status === 200) {
        const responseData = response?.data;
        console.log(response);

        setAllProperties(responseData?.results);
        setTotalPages(responseData?.count);
      } else {
        console.error("Request failed with status:", response.status);
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  };

  useEffect(() => {
    window?.scrollTo(0, 0);
    getAllProperties();
  }, [
    pageNumber,
    homeLat,
    homeLng,
    homeCategoryId,
    categoryId,
    homeType,
    offplan,
    lati,
    lngi,
    selectedCity,
    categoryPropId,
    type,
    finalSearchText, 
  ]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setFinalSearchText(searchText); 
    }
  };

  const handleCategoryChange = (value: string) => {
    setCategoryPropId(value);
  };
  // const handleSearchChange = async () => {

  //   try {

  //     const response = await axios.get(
  //       `${API_URL}/properties/advance/filter/pagination/?property_label=9c9cd2a0-ef4f-4151-b7ab-17496bd3d6c0`
  //     );

  //     if (response.status === 200) {
  //       setSearchProperty(response.data?.results);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // useEffect(() => {
  //   handleSearchChange();
  // }, [])
  const getPropertyType = async (id) => {
    if (id !== null) {
      try {
        const response = await axios.get(
          `${API_URL}/properties/property/types/?building_type=${id}`
        );
        if (response.status === 200) {
          const responseData = response.data;
          setPropertyType(responseData);
        } else {
          toast.error(" Please Contact Administrator");
        }
      } catch (err) {
        toast.error(" Please Contact Administrator");
      }
    }
  };
  const getBuildType = async () => {
    try {
      const response = await axios.get(`${API_URL}/properties/building/types/`);
      if ((response.status = 200)) {
        const responseData = response.data;
        setBuildingType(responseData);
      } else {
        console.error("Request failed with status:", response.status);
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  };
  useEffect(() => {
    getBuildType();
  }, []);
  const handleTypeChange = (value: string) => {
    setType(value);
  };
  const onRefresh = () => {
    // setCategoryPropId("");
    // setPlace("");
    // setType("");
    window.location.reload();
    // navigate("/advance-search", {});
    window?.scrollTo(0, 0);
  };
  useEffect(() => {
    if (searchText !== null) {
      const res = searchProperty.filter((item) => {
        const searchLowerCase = searchText
          ? searchText.toString().toLowerCase()
          : "";
        const searchUpperCase = searchText
          ? searchText.toString().toUpperCase()
          : "";

        const propertyName = item?.property_name || "";
        const streetAddress = item?.street_address || "";

        return (
          searchLowerCase === "" ||
          propertyName.toString().toLowerCase().includes(searchLowerCase) ||
          streetAddress.toString().toLowerCase().includes(searchLowerCase) ||
          propertyName.toString().toUpperCase().includes(searchUpperCase) ||
          streetAddress.toString().toUpperCase().includes(searchUpperCase)
        );
      });

      if (res.length > 0) {
        setFilteredValue(res);
      } else {
        setFilteredValue([]);
      }
    }
  }, [searchText]);

  return (
    <div>
      <div
        className="breadcumb-wrapper"
        style={{ backgroundImage: "url('assets/img/bg/breadcumb-bg.jpg')" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <div className="breadcumb-content">
                <h1 className="breadcumb-title">Properties</h1>
              </div>
            </div>
            <div className="btn-wrap justify-content-center mt-5">
                  <a
                    style={{ cursor: "pointer" }}
                    // onClick={() => residentialFunction(buildingType[1]?.id)}
                    className="th-btn btn_class"
                    href="/Residentialproperties"
                  >
                    Residential
                  </a>

                  <a
                    className="th-btn btn_class"
                    style={{ cursor: "pointer" }}
                    // onClick={() => commericalFunction(buildingType[0]?.id)}
                    href="/Commercialproperties"
                  >
                    Commercial
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    // onClick={() => offPlanFunction()}
                    className="th-btn btn_class"
                    href="/OffPlanproperties"
                  >
                    Off Plan
                  </a>
                </div>
          </div>
        </div>
      </div>
      <section className="space-top space-extra-bottom">
        <div className="container">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="rent-tab-pane"
              role="tabpanel"
              aria-labelledby="rent-tab"
            >
              <div className="property-search-form">
                <label>Property Search</label>
                <div className="form-group">
                  <i className="far fa-search"></i>
                  <AutoComplete
                    apiKey={"AIzaSyDZSiGDJcQdFDZqQU5ONXF7dGkoKQePszo"}
                    onPlaceSelected={(place) => {
                      setPlace(place);
                    }}
                    className="form-control"
                    placeholder="Enter Location..."
                    onKeyDown={(event) => {
                      if (event?.keyCode === 18) {
                        event?.preventDefault();
                      }
                    }}
                    // onChange={handleSearch}
                    options={{
                      types: ["route"],
                      componentRestrictions: { country: "ae" },
                      // types: ["(regions)"],
                    }}
                  />
                </div>
                <select
                  className="form-select"
                  onChange={(e) => {
                    handleCategoryChange(e.target.value);
                    getPropertyType(e.target.value);
                  }}
                >
                  <option value="">Category</option>
                  {buildingType?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select"
                  onChange={(e) => handleTypeChange(e.target.value)}
                >
                  <option value="">Type</option>
                  {propertyType?.map((item) => (
                    <option key={item.id} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>

                <div className="form-group">
                  <div
                    className=""
                    style={{
                      borderLeft: "1px solid #1C2D37",
                      height: "18px",
                      display: "inline",
                    }}
                  >
                    <input
                      id="le_lu_ri"
                      type="text"
                      style={{ height: "18px" }}
                      data-kt-user-table-filter="search"
                      value={searchText} // Controlled input
                      onChange={(e) => setSearchText(e.target.value)} // Update state on change
                      onKeyDown={handleKeyDown} // Trigger API call only on Enter key press
                      placeholder="Enter Keyword..."
                    />
                  </div>
                </div>
                <button className="th-btn" onClick={onRefresh}>
                  <i className="far fa-refresh"></i> Refresh
                </button>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="buy-tab-pane"
              role="tabpanel"
              aria-labelledby="buy-tab"
            >
              <form className="property-search-form">
                <label>Property Search</label>
                <div className="form-group">
                  <i className="far fa-search"></i>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Lisiting ID or Location"
                  />
                </div>
                <select className="form-select">
                  <option value="category">Category</option>
                  <option value="luxury">Luxury</option>
                  <option value="commercial">Commercial</option>
                </select>
                <select className="form-select">
                  <option value="offer_type">Offer Type</option>
                  <option value="popularity">Popularity</option>
                  <option value="rating">Rating</option>
                  <option value="date">Latest</option>
                </select>
                <button className="th-btn" type="submit">
                  <i className="far fa-search"></i> Search
                </button>
              </form>
            </div>
          </div>
          {/* <div className="th-sort-bar">
            <div className="row justify-content-between align-items-center">
              <div className="col-md">
                <p className="woocommerce-result-count">
                  Showing 1–9 of 16 results
                </p>
              </div>

              <div className="col-md-auto">
                <div className="sorting-filter-wrap">
                  <form className="woocommerce-ordering" method="get">
                    <select
                      name="orderby"
                      className="orderby"
                      aria-label="Shop order"
                    >
                      <option value="menu_order">Default Sorting</option>
                      <option value="popularity">Sort by popularity</option>
                      <option value="rating">Sort by average rating</option>
                      <option value="date">Sort by latest</option>
                      <option value="price">Sort by price: low to high</option>
                      <option value="price-desc">
                        Sort by price: high to low
                      </option>
                    </select>
                  </form>
                   <div className="nav">
                    <a
                      className="active"
                      href="#"
                      id="tab-shop-list"
                      data-bs-toggle="tab"
                      data-bs-target="#tab-list"
                      role="tab"
                      aria-controls="tab-grid"
                      aria-selected="false"
                    >
                      <i className="fa-light fa-grid-2"></i>
                    </a>
                    <a
                      href="#"
                      id="tab-shop-grid"
                      data-bs-toggle="tab"
                      data-bs-target="#tab-grid"
                      role="tab"
                      aria-controls="tab-grid"
                      aria-selected="true"
                    >
                      <i className="fa-solid fa-list"></i>
                    </a>
                  </div> 
                </div>
              </div>
            </div>
          </div> */}
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade active show"
              id="tab-list"
              role="tabpanel"
              aria-labelledby="tab-shop-list"
            >
              <div className="row gy-40">
                {(filteredValue?.length > 0
                  ? filteredValue
                  : allProperties
                )?.map((item) => (
                  <div className="col-md-6 col-xl-4" key={item?.id}>
                    <div className="property-card2">
                      <div className="property-card-thumb img-shine">
                        <Link to={`/property/${item?.slug}`}>
                          <img
                            src={item?.upload_featured_image}
                            alt="PropertyImage"
                          />
                        </Link>
                      </div>
                      <div className="property-card-details">
                        <div className="media-left" style={{ height: "250px" }}>
                          <h4 className="property-card-title">
                            <Link to={`/property/${item?.slug}`}>
                              {item?.property_name}
                            </Link>
                          </h4>
                          <h5 className="property-card-price">
                            <span className="text-black font-weight-500 fs-16">
                              AED{" "}
                            </span>
                            <CurrencyFormat
                              value={item?.property_price}
                              displayType="text"
                              thousandSeparator={true}
                              suffix=".00"
                            />
                            {/* {item?.property_price > 0
                              ? `AED ${item?.property_price}`
                              : `AED ${item?.lease_price_per_mo}`} */}
                          </h5>
                          <p className="property-card-location">
                            {item?.street_address}
                          </p>
                        </div>
                        <div className="btn-wrap">
                          <Link
                            to={`/property/${item?.slug}`}
                            className="th-btn style-border2"
                          >
                            Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-60 text-center">
            <div className="th-pagination">
              <ul>
                <li>
                  <a
                    className={`prev-page ${
                      pageNumber === 1 ? "disabled" : ""
                    }`}
                    onClick={handlePreviousPage}
                    href="#"
                  >
                    <i className="far fa-arrow-left me-2"></i>Previous
                  </a>
                </li>

                {/* Generate page numbers dynamically */}
                {Array.from({
                  length: Math.min(5, totalPages - currentRange[0] + 1),
                }).map((_, index) => {
                  const page = currentRange[0] + index;
                  return (
                    <li key={page}>
                      <a
                        className={pageNumber === page ? "active" : ""}
                        onClick={() => handlePageClick(page)}
                        href="#"
                      >
                        {page}
                      </a>
                    </li>
                  );
                })}

                {/* Show Next button only if not on the last page */}
                {pageNumber < totalPages && (
                  <li>
                    <a className="next-page" onClick={handleNextPage}>
                      Next<i className="far fa-arrow-right ms-2"></i>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Properties;
